/* eslint-disable */
import React, { useState, useMemo } from 'react';

import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { setWallets, setTrackingWallets } from 'store/reducers/wallet';

// material-ui
import {
  Grid,
  IconButton,
  Typography,
  TextField,
  Box,
  Button,
  Modal,
  FormControl,
  Select,
  InputLabel,
  Divider,
  MenuItem,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// icons
import { GrClose } from 'react-icons/gr';
import { IoAdd, IoReloadOutline } from 'react-icons/io5';

// utils import
import nanoNknToNkn from 'utils/nanoNknToNkn';
import { fNumber2, fCurrency } from 'utils/formatNumber';

// api import
import nknApi from 'api/nknApi';
import networkNodeApi from 'api/networkNodeApi';

// component import
import MainCard from 'components/MainCard';
// import WalletBalanceChart from './WalletBalanceChart';
import WalletCard from './WalletCard';
import sleep from 'utils/sleep';

// ==============================|| WALLETS - DEFAULT ||============================== //

const Wallets = () => {
  const [totalBalance, setTotalBalance] = useState(0);
  const [loading, setLoading] = useState(false);

  const [showAddWalletModal, setShowAddWalletModal] = useState(false);

  const [walletAddressInputValue, setWalletAddressInputValue] = useState('');
  const [labelInputValue, setLabelInputValue] = useState('');
  const [walletType, setWalletType] = useState('personal');
  const { wallets: persistWallets, trackingWallets: persistTrackingWallets } = useSelector(
    (state) => state.wallet,
  );
  const [wallets, setWalletsData] = useState(persistWallets);
  const [trackingWallets, setTrackingWalletsData] = useState(persistTrackingWallets);
  const [donateWalletsData, setWalletDonateData] = useState({
    address: 'NKNCTCu64Eg87tJX55wF3k4AGRenzZUFqjhK',
    name: 'nknag-donate-wallet',
    balance: 0,
  });
  const [usdPrice, setUsdPrice] = useState(0);

  const dispatch = useDispatch();

  const getWalletInfo = async () => {
    setLoading(true);
    try {
      const walletsInfo = await Promise.all(
        persistWallets.map(async (data) => {
          const res = await networkNodeApi.getWalletInfo(data.address);

          return { ...res.data, ...data, balance: res.data.balance };
        }),
      );

      const trackingWalletsInfo = await Promise.all(
        persistTrackingWallets.map(async (data) => {
          const res = await networkNodeApi.getWalletInfo(data.address);

          return { ...res.data, ...data, balance: res.data.balance };
        }),
      );

      const walletDonateData = await networkNodeApi.getWalletInfo(donateWalletsData.address);

      setWalletsData(walletsInfo);
      setTrackingWalletsData(trackingWalletsInfo);
      setWalletDonateData({
        ...walletDonateData.data,
        ...donateWalletsData,
        balance: walletDonateData.data.balance,
      });
      /*eslint-disable */
      const balanceCalculator = walletsInfo.reduce((total, wallet) => (total += wallet.balance), 0);

      setTotalBalance(nanoNknToNkn(balanceCalculator));
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getWalletInfo();
    getUsdPrice();
  }, [persistWallets, persistTrackingWallets]);

  const getUsdPrice = async () => {
    setLoading(true);
    try {
      const res = await nknApi.getNKNPrice();

      if (res?.data?.nkn) {
        setUsdPrice(res.data.nkn.usd);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenAddWalletModal = () => {
    setShowAddWalletModal(true);
  };
  const handleCloseAddWalletModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setShowAddWalletModal(false);
  };

  const handleAddWallet = async () => {
    try {
      if (!walletAddressInputValue) return;

      if (walletType === 'personal') {
        if (persistWallets.some((wallet) => wallet.address === walletAddressInputValue.trim()))
          return toast.error('Wallet address already exists');

        if (persistWallets.some((wallet) => wallet.name === labelInputValue.trim()))
          return toast.error('Wallet name already exists');
        dispatch(
          setWallets([
            ...persistWallets,
            { address: walletAddressInputValue, name: labelInputValue, balance: 0 },
          ]),
        );
      } else {
        if (
          persistTrackingWallets.some((wallet) => wallet.address === walletAddressInputValue.trim())
        )
          return toast.error('Wallet address already exists');

        if (persistTrackingWallets.some((wallet) => wallet.name === labelInputValue.trim()))
          return toast.error('Wallet name already exists');
        dispatch(
          setTrackingWallets([
            ...persistTrackingWallets,
            { address: walletAddressInputValue, name: labelInputValue, balance: 0 },
          ]),
        );
      }

      toast.success('Wallet added successfully');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setShowAddWalletModal(false);
      setLabelInputValue('');
      setWalletAddressInputValue('');
    }
  };

  const handleDeleteWallet = (address) => {
    const newWallets = persistWallets.filter((wallet) => wallet.address !== address);
    dispatch(setWallets(newWallets));
    toast.success('Wallet deleted successfully');
  };

  const handleDeleteTrackingWallet = (address) => {
    const newWallets = persistTrackingWallets.filter((wallet) => wallet.address !== address);
    dispatch(setTrackingWallets(newWallets));
    toast.success('Wallet deleted successfully');
  };

  const handleRefreshData = async () => {
    setLoading(true);
    await sleep(1000);
    getWalletInfo();
    getUsdPrice();
  };

  const usdBalance = useMemo(() => {
    return fCurrency(totalBalance * usdPrice);
  }, [usdPrice, totalBalance]);

  return (
    <>
      <Grid container spacing={{ xs: 1, md: 2, lg: 2, sm: 2 }}>
        <Grid item xs={12} md={6} lg={6}>
          <Typography variant="h5">Wallets</Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Box
            sx={{
              display: 'flex',
              mt: 1,
              justifyContent: 'flex-end',
              width: '100%',
            }}>
            <Button
              variant="contained"
              size="small"
              endIcon={<IoAdd />}
              onClick={handleOpenAddWalletModal}>
              Add Wallet
            </Button>
            &nbsp; &nbsp;
            <LoadingButton
              loading={loading}
              variant="contained"
              size="small"
              endIcon={<IoReloadOutline />}
              onClick={handleRefreshData}>
              Refresh Data
            </LoadingButton>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container spacing={{ xs: 1, md: 2, lg: 2, sm: 2 }}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h3">
                <span style={{ color: '#757981', fontSize: 18 }}>
                  Tracking Wallets ({persistTrackingWallets.length})
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <WalletCard
                usdPrice={usdPrice}
                address={donateWalletsData.address}
                name={donateWalletsData.name}
                balance={donateWalletsData.balance}
                title={'Buy Me a Coffee, domain renewal fees,..'}
              />
            </Grid>
            {trackingWallets.map((wallet) => (
              <Grid key={wallet.address} item xs={12} sm={6} md={4} lg={3}>
                <WalletCard
                  usdPrice={usdPrice}
                  address={wallet.address}
                  name={wallet.name}
                  balance={wallet.balance}
                  onDelete={handleDeleteTrackingWallet}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Divider sx={{ my: 2 }} />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container spacing={{ xs: 1, md: 2, lg: 2, sm: 2 }}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h3">
                <span style={{ color: '#757981', fontSize: 18 }}>
                  Personal Wallets ({persistWallets.length}):{' '}
                </span>
                {fNumber2(totalBalance)} <span style={{ fontSize: 17 }}>NKN</span> (
                <span style={{ color: 'green', fontSize: 20 }}>{usdBalance}</span>)
              </Typography>
            </Grid>

            {wallets.map((wallet) => (
              <Grid key={wallet.address} item xs={12} sm={6} md={4} lg={3}>
                <WalletCard
                  usdPrice={usdPrice}
                  address={wallet.address}
                  name={wallet.name}
                  balance={wallet.balance}
                  onDelete={handleDeleteWallet}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={showAddWalletModal}
        onClose={handleCloseAddWalletModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={{
            position: 'absolute',
            top: '30%',
            left: '50%',
            width: {
              lg: 650,
              md: 600,
              sm: 500,
              xs: '90%',
            },
            transform: 'translate(-50%, -50%)',
          }}>
          <MainCard sx={{ p: 1, position: 'relative' }}>
            <IconButton
              color="error"
              onClick={handleCloseAddWalletModal}
              sx={{
                position: 'absolute',
                right: 2,
                top: 2,
                '&:hover': {
                  bgcolor: (theme) => theme.palette.grey[300],
                  color: (theme) => theme.palette.common.white,
                },
              }}>
              <GrClose />
            </IconButton>
            <form noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography align="center" variant="h5">
                    Add New Wallet
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={walletAddressInputValue}
                    onChange={(e) => {
                      setWalletAddressInputValue(e.target.value);
                    }}
                    size="small"
                    label="Wallet Address"
                    variant="outlined"
                    fullWidth
                    inputProps={{
                      spellCheck: false,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={labelInputValue}
                    onChange={(e) => {
                      setLabelInputValue(e.target.value);
                    }}
                    fullWidth
                    size="small"
                    label="Wallet Name"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <FormControl size="medium" fullWidth>
                      <InputLabel id="wallet-type-select">Type</InputLabel>
                      <Select
                        labelId="wallet-type-select"
                        id="demo-simple-select"
                        value={walletType}
                        onChange={(e) => {
                          setWalletType(e.target.value);
                        }}
                        sx={{ height: 35.13 }}
                        label="Type">
                        <MenuItem value="tracking">Tracking Wallets</MenuItem>
                        <MenuItem value="personal">Personal Wallets</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Button onClick={handleAddWallet} size="small" variant="contained">
                    Add
                  </Button>
                </Grid>
              </Grid>
            </form>
          </MainCard>
        </Box>
      </Modal>
    </>
  );
};

export default Wallets;
