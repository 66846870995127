import { createRoot } from 'react-dom/client';
import { HashRouter } from 'react-router-dom';

// css import
import 'assets/css/index.css';

// third-party
import { Provider as ReduxProvider } from 'react-redux';

// project import
import { store } from 'store';
import App from './App';

import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <ReduxProvider store={store}>
    <HashRouter>
      <App />
    </HashRouter>
  </ReduxProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
