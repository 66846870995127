import { TbNetwork, TbMailForward } from 'react-icons/tb';
import { BsBootstrapReboot } from 'react-icons/bs';
import { GiDuration } from 'react-icons/gi';
import { IoWalletOutline } from 'react-icons/io5';
import { HiOutlineCircleStack } from 'react-icons/hi2';
import { CgPerformance } from 'react-icons/cg';
import { AiOutlineGift, AiFillGift } from 'react-icons/ai';

const nodeSchema = {
  name: {
    type: 'string',
  },
  ip: {
    type: 'string',
    required: true,
    disabled: true,
    icon: TbNetwork,
  },
  syncState: {
    type: 'string',
    disabled: true,
    required: true,
  },
  walletAddress: {
    type: 'string',
    icon: IoWalletOutline,
  },
  syncStateChanged: {
    type: 'integer',
    required: true,
    hidden: true,
  },
  prevSyncState: {
    type: 'string',
    hidden: true,
    required: true,
  },
  height: {
    type: 'integer',
    icon: HiOutlineCircleStack,
    align: 'center',
  },
  relayMessageCount: {
    type: 'integer',
    icon: TbMailForward,
    align: 'center',
  },
  relayHourly: {
    type: 'integer',
    icon: CgPerformance,
    align: 'center',
  },
  proposalSubmitted: {
    type: 'integer',
    icon: AiOutlineGift,
  },
  totalProposalSubmitted: {
    type: 'integer',
    icon: AiFillGift,
  },
  publicKey: {
    type: 'string',
  },
  nodeId: {
    type: 'string',
  },
  version: {
    type: 'string',
  },
  uptime: {
    type: 'integer',
    icon: GiDuration,
    align: 'left',
  },
  restarted: {
    type: 'integer',
    icon: BsBootstrapReboot,
  },
  createdAt: {
    type: 'datetime',
  },
  updatedAt: {
    type: 'datetime',
  },
};

export default nodeSchema;
