// @mui
import {
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  IconButton,
  Popover,
  Typography,
  Box,
  OutlinedInput,
  Tooltip,
} from '@mui/material';

import { useState } from 'react';

// constants import

// icons import
// import { IoSettingsSharp } from 'react-icons/io5';
import { BsCalendarRange } from 'react-icons/bs';

// lib import
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  setFromMonth,
  setToMonth,
  setIsCustom,
  setTimeUnit,
  setTimeValue,
} from 'store/reducers/node';

// hooks import

// project import
import AnimateButton from 'components/@extended/AnimateButton';

// time unit day, week, month, year
const DATE_RANGE_OPTIONS = [
  {
    title: 'Default',
    value: 50,
    timeUnit: 'year',
  },
  {
    title: 'This month',
    value: 1,
    timeUnit: 'month',
  },
  {
    title: '7 days',
    value: 7,
    timeUnit: 'day',
  },
  {
    title: '14 days',
    value: 14,
    timeUnit: 'day',
  },
  {
    title: '30 days',
    value: 30,
    timeUnit: 'day',
  },
  {
    title: '180 days',
    value: 180,
    timeUnit: 'day',
  },
  {
    title: '365 days',
    value: 365,
    timeUnit: 'day',
  },
];

function DateRange() {
  const { fromMonth, toMonth, isCustom, timeValue } = useSelector((state) => state.node);

  const dispatch = useDispatch();
  const [openDetail, setOpenDetail] = useState(null);
  const [dateRangeValue, setDateRangeValue] = useState(timeValue);

  const handleOpenPopOver = (event) => {
    setOpenDetail(event.currentTarget);
  };
  const handleClosePopOver = () => {
    setOpenDetail(null);
  };

  const handleSetFromMonth = (e) => {
    const { value } = e.target;
    if (!value) return;
    dispatch(setFromMonth({ value: moment.utc(value).startOf('day').toISOString() }));
    dispatch(setIsCustom(true));
  };

  const handleSetToMonth = (e) => {
    const { value } = e.target;

    if (!value) return;
    dispatch(setToMonth({ value: moment.utc(value).endOf('day').toISOString() }));
    dispatch(setIsCustom(true));
  };

  const handleSelectOption = (e, { props }) => {
    const { value } = props;

    const { timeUnit } = DATE_RANGE_OPTIONS.find((option) => option.value === value);

    const startOfTime = moment.utc().startOf(timeUnit);
    const endOfTime = moment.utc().endOf(timeUnit);

    let from = moment.utc(startOfTime).subtract(value, timeUnit);

    if (timeUnit === 'month') {
      from = moment.utc(startOfTime).subtract(value - 1, timeUnit);

      if (value >= 12) {
        from = moment
          .utc(startOfTime)
          .subtract(Math.floor(value / 12), 'year')
          .subtract(value % 12, 'month');
      }

      from = moment.utc().year(from.year()).month(from.month()).startOf(timeUnit);
    }

    // console.log(from.toLocaleString());
    // console.log(endOfTime.toLocaleString());

    dispatch(setTimeUnit(timeUnit));
    dispatch(setTimeValue(value));
    dispatch(setFromMonth({ value: from.toISOString() }));
    dispatch(setToMonth({ value: endOfTime.toISOString() }));
    dispatch(setIsCustom(false));
    setDateRangeValue(value);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <FormControl sx={{ minWidth: 100, opacity: isCustom ? 0.4 : 1 }}>
        <InputLabel sx={{}} id="created-at-select-label">
          Created At
        </InputLabel>
        <Select
          // labelId="created-at-select-label"
          id="created-at-select"
          value={dateRangeValue}
          label="Created At"
          size="small"
          onChange={handleSelectOption}
        >
          {DATE_RANGE_OPTIONS.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      &nbsp;
      <Tooltip title="Created At Customize">
        <IconButton
          component="span"
          disableRipple
          sx={{
            width: 28.13,
            height: 28.13,
            '&:hover': {
              bgcolor: 'grey.300',
            },
          }}
          aria-haspopup="true"
          onClick={handleOpenPopOver}
          color="inherit"
        >
          <AnimateButton>
            <BsCalendarRange />
          </AnimateButton>
        </IconButton>
      </Tooltip>
      <Popover
        open={Boolean(openDetail)}
        anchorEl={openDetail}
        onClose={handleClosePopOver}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Box sx={{ px: 2, pb: 3, pt: 1 }}>
          <Typography align="center" variant="subtitle1" sx={{ pb: 1 }}>
            Date range select
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <Box>
              <OutlinedInput
                value={moment.utc(fromMonth).format('YYYY-MM-DD')}
                onChange={handleSetFromMonth}
                sx={{ bgcolor: 'common.white' }}
                size="small"
                type="date"
              />
            </Box>
            &nbsp;
            <Box>
              <span>To </span>
              <OutlinedInput
                value={moment.utc(toMonth).format('YYYY-MM-DD')}
                onChange={handleSetToMonth}
                sx={{ bgcolor: 'common.white' }}
                size="small"
                type="date"
              />
            </Box>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}

export default DateRange;
