// constants import
import reactRouterConstants from 'assets/constants/reactRouterConstants';
// assets
import { IoWalletOutline } from 'react-icons/io5';
// icons
const icons = {
  IoWalletOutline,
};

// ==============================|| MENU WALLETS - wallets ||============================== //

const wallets = {
  id: 'group-wallets',
  title: 'Wallet',
  type: 'group',
  children: [
    {
      id: reactRouterConstants.WALLETS,
      title: 'Wallets',
      type: 'item',
      url: reactRouterConstants.WALLETS,
      icon: icons.IoWalletOutline,
      breadcrumbs: false,
    },
  ],
};

export default wallets;
