import React from 'react';
// @mui
import { Box, MenuItem, FormControl, InputLabel, Select } from '@mui/material';

// lib import

// hook import
import useResponsive from 'hooks/useResponsive';

// redux
import { useSelector } from 'react-redux';

// project import
import NodeUpdatedAtFilter from 'components/date-range/NodeUpdatedAtFilter';
import NodeCreatedAtFilter from 'components/date-range/NodeDateRange';
import DateRangeMini from 'components/date-range/DateRangeMini';

function NodeToolbar({ onChangeSyncStateFilter = () => {}, defaultState }) {
  const {
    fromMonth,
    toMonth,
    updatedAtLastHours,
    nodes: persistNode,
  } = useSelector((state) => state.node);

  // const timeFilter = React.useMemo(() => {
  //   const now = moment();
  //   if (updatedAtLastHours === 'none') return {};
  //   return {
  //     updatedAt: {
  //       gte: now.subtract(updatedAtLastHours, 'hour').toISOString(),
  //     },
  //   };
  // }, [updatedAtLastHours]);

  const [selectState, setSelectState] = React.useState(defaultState);

  const [distinctState, setDistinctState] = React.useState(null);

  // distinct sync state api
  React.useEffect(() => {
    if (onChangeSyncStateFilter) {
      const stateArr = persistNode.map((node) => node.syncState);
      const stateCount = {};

      stateArr.forEach((state) => {
        stateCount[state] = (stateCount[state] || 0) + 1;
      });

      const distinctStateRes = Array.from(new Set(stateArr));

      if (!distinctStateRes.includes(defaultState)) {
        distinctStateRes.push(defaultState);
      }

      const result = distinctStateRes.map((state) => ({
        state,
        count: stateCount[state] || 0,
      }));

      setDistinctState(result);
    }
  }, [fromMonth, toMonth, updatedAtLastHours, persistNode]);

  const handleChangeSyncState = (e) => {
    const { value } = e.target;

    onChangeSyncStateFilter(() => {
      if (value === 'all') return {};
      return {
        syncState: {
          eq: e.target.value,
        },
      };
    });

    setSelectState(e.target.value);
  };

  // responsive
  const isMobile = useResponsive('down', 'sm');

  /* eslint-disable */
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: {
          xs: 'space-between',
          lg: 'flex-start',
          md: 'flex-start',
          sm: 'flex-start',
        },
        px: { lg: 3, xs: 0, md: 3, sm: 3 },
        mb: 5,
      }}>
      {onChangeSyncStateFilter && (
        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel id="sync-state-select-label">Sync State</InputLabel>
          <Select
            id="sync-state-select"
            value={selectState}
            label="Sync State"
            size="small"
            onChange={handleChangeSyncState}>
            <MenuItem value="all">{`ALL (${
              distinctState?.reduce(
                /* eslint-disable-next-line */
                (acc, cur) => (acc += cur.count),
                0,
              ) || 0
            })`}</MenuItem>

            {distinctState ? (
              distinctState.map((state) => (
                <MenuItem key={state.state} value={state.state}>
                  {`${state.state} (${state.count})`}
                </MenuItem>
              ))
            ) : (
              <MenuItem value={defaultState}>{defaultState}(0)</MenuItem>
            )}
          </Select>
        </FormControl>
      )}

      {!isMobile ? (
        <>
          &nbsp; &nbsp;
          <NodeUpdatedAtFilter />
          &nbsp; &nbsp;
          <NodeCreatedAtFilter />
        </>
      ) : (
        <DateRangeMini />
      )}
    </Box>
  );
}

export default NodeToolbar;
