// ==============================|| OVERRIDES - SELECT ||============================== //

export default function Select(theme) {
  return {
    MuiSelect: {
      styleOverrides: {
        root: {
          height: 28.13,
          fontSize: '0.9rem',
          color: theme.palette.text.primary,
        },
      },
    },
  };
}
