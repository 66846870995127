/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

import localStorageConstants from 'assets/constants/localStorageConstants';

const persistWallets = JSON.parse(localStorage.getItem(localStorageConstants.WALLETS)) || [];
const persistTrackingWallets = JSON.parse(
  localStorage.getItem(localStorageConstants.TRACKING_WALLETS),
) || [
  {
    address: 'NKNHb4UMs8whWosffseg7aPpztVNBt5NDrCR',
    name: 'swap.nkn.org',
    balance: 0,
  },
  {
    address: 'NKNEfKFwLjdN2SXJU2UZaY3aECVuC6kTjwzz',
    name: 'npool',
    balance: 0,
  },
  {
    address: 'NKNEADdKX6ZmhqKosk7M3Nht3P9TkdGwBEH1',
    name: 'top-miner-02',
    balance: 0,
  },
  {
    address: 'NKNYCZCBgiaQdBoECcTgDN1M7nqJ8Eow7CFg',
    name: 'top-miner-03',
    balance: 0,
  },
];

const initialState = {
  wallets: persistWallets,
  trackingWallets: persistTrackingWallets,
};

const wallet = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWallets(state, action) {
      state.wallets = action.payload;
    },
    setTrackingWallets(state, action) {
      state.trackingWallets = action.payload;
    },
  },
});

export default wallet.reducer;

export const { setWallets, setTrackingWallets } = wallet.actions;
