/*eslint-disable */
export default function secondsToDHMS(_value) {
  const value = Number(_value);
  const dd = Math.floor(value / 86400);
  const h = Math.floor((value % 86400) / 3600);
  const m = Math.floor((value % 3600) / 60);
  const s = Math.floor((value % 3600) % 60);

  const ddDisplay = dd > 0 ? dd.toString().padStart(2, '0') + 'd, ' : '00d, ';
  const hDisplay = h > 0 ? h.toString().padStart(2, '0') + 'h' : '00h';
  const mDisplay = m > 0 ? m.toString().padStart(2, '0') + 'm' : '00m';
  const sDisplay = s > 0 ? s.toString().padStart(2, '0') + 's' : '00s';

  if (dd > 0) {
    return ddDisplay + hDisplay + mDisplay + sDisplay;
  }

  return hDisplay + mDisplay + sDisplay;
}
