/* eslint-disable */
import React, { useState, useMemo } from 'react';

// lib import
import moment from 'moment';

// redux import
import { useSelector, useDispatch } from 'react-redux';

// router import
import { Link } from 'react-router-dom';
import { activeItem } from 'store/reducers/menu';

// api import
import nknApi from 'api/nknApi';
import networkNodeApi from 'api/networkNodeApi';

// utils import
import { fNumber, fNumber2 } from 'utils/formatNumber';
import nanoNknToNkn from 'utils/nanoNknToNkn';

// material-ui
import {
  Avatar,
  Grid,
  Box,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';

// icons
import { MoneyCollectOutlined, TransactionOutlined, WalletOutlined } from '@ant-design/icons';

import { BiWorld, BiCoinStack } from 'react-icons/bi';
import { FaInternetExplorer } from 'react-icons/fa';
import { AiOutlineTransaction } from 'react-icons/ai';
import { TbCloudComputing } from 'react-icons/tb';
import { VscLayersActive } from 'react-icons/vsc';
import { RiFocus3Line } from 'react-icons/ri';

// constants import
import reactRouterConstants from 'assets/constants/reactRouterConstants';

// project import
import MainCard from 'components/MainCard';
import DashboardWidget from './DashboardWidget';
import MarketStatistics from './MarketStatistics';
import NodesStatusChart from './NodesStatusChart';

// avatar style
const avatarSX = {
  width: 50,
  height: 50,
  fontSize: '1rem',
  '& svg': {
    fontSize: 23,
  },
};

// action style
const actionSX = {
  mt: 1.55,
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',
  transform: 'none',
};

// ==============================|| DASHBOARD - DEFAULT ||============================== //

const DashboardDefault = () => {
  const dispatch = useDispatch();
  const {
    fromMonth,
    toMonth,
    updatedAtLastHours,
    nodes: persistNode,
  } = useSelector((state) => state.node);

  const { wallets: persistWallets } = useSelector((state) => state.wallet);

  const timeFilter = useMemo(() => {
    const now = moment();
    if (updatedAtLastHours === 'none') return {};
    return {
      updatedAt: {
        gte: now.subtract(updatedAtLastHours, 'hour').toISOString(),
      },
    };
  }, [updatedAtLastHours]);

  const createdAtRange = useMemo(() => {
    const from = moment.utc(fromMonth);
    const to = moment.utc(toMonth);
    const diff = to.diff(from, 'days');
    if (diff > 730) return 0;

    // console.log(from.toLocaleString());
    // console.log(to.toLocaleString());

    if (from.month() === to.month()) {
      const daysInCurrentMonth = moment.utc().month(from.month()).daysInMonth();
      if (diff === daysInCurrentMonth - 1) {
        return from.format('MMM');
      }
      // return `${from.format('DD')}-${to.format('DD/MMM')}`;
    }
    return `${diff}d`;
  }, [fromMonth, toMonth]);

  const dateRangeFilter = {
    createdAt: {
      gt: fromMonth,
      lte: toMonth,
    },
  };

  /// state
  const [totalNode, setTotalNode] = useState(0);
  const [onlineNode, setOnlineNode] = useState(0);
  const [totalMined, setTotalMined] = useState(0);

  const [totalBalance, setTotalBalance] = useState(0);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [totalWallets, setTotalWallets] = useState(0);

  // coin api state
  const [nknMarketData, setNknMarketData] = useState();

  // network nodes state

  const [nodesSummary, setNodesSummary] = useState(0);
  const [nodesAddresses, setNodesAddresses] = useState(0);
  const [nodesBlocks, setNodesBlocks] = useState(0);
  const [nodesTransactions, setNodesTransactions] = useState(0);

  //
  React.useEffect(() => {
    setOnlineNode(
      persistNode.filter((node) =>
        ['PERSIST_FINISHED', 'WAIT_FOR_SYNCING', 'SYNC_FINISHED', 'SYNC_STARTED'].includes(
          node.syncState,
        ),
      ).length,
    );
    setTotalNode(persistNode.length);
    setTotalMined(persistNode.reduce((total, node) => (total += node.proposalSubmitted), 0));

    (async () => {
      try {
        const walletsAddresses = persistWallets.map((wallet) => wallet.address);
        setTotalWallets(persistWallets.length);
        const walletsInfo = await Promise.all(
          walletsAddresses.map(async (addrr) => {
            const res = await networkNodeApi.getWalletInfo(addrr);

            return res.data;
          }),
        );

        /*eslint-disable */
        const totalBalanceCal = walletsInfo.reduce((total, wallet) => (total += wallet.balance), 0);
        const totalTransactionsCal = walletsInfo.reduce(
          (total, wallet) => (total += wallet.count_transactions),
          0,
        );
        setTotalBalance(nanoNknToNkn(totalBalanceCal));
        setTotalTransactions(totalTransactionsCal);
      } catch (error) {
        console.log(error.message);
      }
    })();
  }, [fromMonth, toMonth, timeFilter, persistWallets, persistNode]);

  // node summary
  React.useEffect(() => {
    // summary nodes in the world
    (async () => {
      const nodesSummaryRes = await networkNodeApi.getGeoNodeSummary();

      const nodesTransactionsRes = await networkNodeApi.getNodeTransactions();

      const nodesAddressesRes = await networkNodeApi.getNodeAddresses();

      const nodesBlocksRes = await networkNodeApi.getNodeBlocks();

      if (nodesSummaryRes?.data?.Payload) {
        setNodesSummary(nodesSummaryRes.data.Payload);
      }
      if (nodesBlocksRes?.data?.sumBlocks) {
        setNodesBlocks(nodesBlocksRes.data.sumBlocks);
      }
      if (nodesTransactionsRes?.data?.sumTransactions) {
        setNodesTransactions(nodesTransactionsRes.data.sumTransactions);
      }
      if (nodesAddressesRes?.data?.sumAddresses) {
        setNodesAddresses(nodesAddressesRes.data.sumAddresses);
      }
    })();
  }, []);

  // gecko api
  React.useEffect(() => {
    (async () => {
      try {
        const res = await nknApi.getNKNCurrentData();

        if (res?.data?.market_data) {
          setNknMarketData(res.data.market_data);
        }
      } catch (error) {
        console.log(error);
      }
    })();

    const intervalId = setInterval(() => {
      (async () => {
        try {
          const res = await nknApi.getNKNCurrentData();

          if (res?.data?.market_data) {
            setNknMarketData(res.data.market_data);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleClickRouterLink = (routerId) => {
    dispatch(activeItem({ openItem: [routerId] }));
  };

  const controlNodesRatio = onlineNode / nodesSummary?.totalCount || 0;

  return (
    <Grid container spacing={{ xs: 1, md: 2, lg: 2, sm: 2 }}>
      {/* row 1 */}
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Typography variant="h5">Dashboard</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <NodesStatusChart />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <DashboardWidget
          showTimeFilter={true}
          title={'Your Network Control'}
          value={`${(controlNodesRatio * 100).toFixed(3)}%`}
          list={[
            {
              name: createdAtRange ? `Total nodes (${createdAtRange})` : 'Total nodes',
              value: totalNode,
              icon: <TbCloudComputing />,
              color: 'info',
              url: reactRouterConstants.NODES_TABLE,
            },
            {
              name:
                updatedAtLastHours === 'none'
                  ? 'Active nodes'
                  : `Active nodes (${updatedAtLastHours}h)`,
              value: onlineNode,
              icon: <VscLayersActive />,
              color: 'success',
              url: reactRouterConstants.NODES,
            },
            {
              name: createdAtRange ? `Mined total (${createdAtRange})` : 'Mined total',
              color: 'warning',
              value: totalMined,
              icon: <RiFocus3Line />,
            },
          ]}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <DashboardWidget
          title={'Current Network Nodes'}
          value={fNumber(nodesSummary?.totalCount)}
          list={[
            {
              name: 'Countries',
              value: nodesSummary?.summary?.length,
              icon: <BiWorld />,
            },
            {
              name: 'Addresses',
              value: fNumber(nodesAddresses),
              icon: <FaInternetExplorer />,
            },
            { name: 'Blocks', value: fNumber(nodesBlocks), icon: <BiCoinStack /> },
            {
              name: 'Transactions',
              value: fNumber(nodesTransactions),
              icon: <AiOutlineTransaction />,
            },
          ]}
        />
      </Grid>

      {/* row 2 */}

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <MainCard content={false}>
          <Typography sx={{ p: 2 }} align="center" variant="h5" color="textSecondary">
            Personal Wallets Info
          </Typography>
          <List
            component="nav"
            sx={{
              px: 2,
              py: 1,
              '& .MuiListItemButton-root': {
                py: 1.5,
                mb: 1.2,
                '& .MuiAvatar-root': avatarSX,
                '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' },
              },
            }}>
            <Link to="/wallets" style={{ textDecoration: 'none', color: '#000' }}>
              <ListItemButton onClick={() => handleClickRouterLink('/wallets')}>
                <ListItemAvatar>
                  <Avatar
                    sx={{
                      color: 'error.main',
                      bgcolor: 'error.lighter',
                    }}>
                    <WalletOutlined />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography variant="subtitle1">Total Wallets</Typography>}
                  secondary="Wallets"
                />
                <ListItemSecondaryAction>
                  <Stack alignItems="flex-end">
                    <Typography variant="h5" noWrap>
                      {totalWallets}
                    </Typography>
                  </Stack>
                </ListItemSecondaryAction>
              </ListItemButton>
            </Link>
            <Link
              to="/wallets"
              onClick={() => handleClickRouterLink('/wallets')}
              style={{ textDecoration: 'none', color: '#000' }}>
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar
                    sx={{
                      color: 'success.main',
                      bgcolor: 'success.lighter',
                    }}>
                    <MoneyCollectOutlined />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography variant="subtitle1">Total Balance</Typography>}
                  secondary="Balance"
                />
                <ListItemSecondaryAction>
                  <Stack alignItems="flex-end">
                    <Typography variant="h5" noWrap>
                      {fNumber2(totalBalance)} NKN
                    </Typography>
                  </Stack>
                </ListItemSecondaryAction>
              </ListItemButton>
            </Link>
            <Link
              to="/wallets"
              onClick={() => handleClickRouterLink('/wallets')}
              style={{ textDecoration: 'none', color: '#000' }}>
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar
                    sx={{
                      color: 'warning.main',
                      bgcolor: 'warning.lighter',
                    }}>
                    <TransactionOutlined />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography variant="subtitle1">Total Transactions</Typography>}
                  secondary="Transactions"
                />
                <ListItemSecondaryAction>
                  <Stack alignItems="flex-end">
                    <Typography variant="h5" noWrap>
                      {fNumber(totalTransactions)}
                    </Typography>
                  </Stack>
                </ListItemSecondaryAction>
              </ListItemButton>
            </Link>
          </List>
        </MainCard>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <MarketStatistics nknMarketData={nknMarketData} />
      </Grid>
    </Grid>
  );
};

export default DashboardDefault;
