// material-ui
import { Box, Typography } from '@mui/material';

// project import
import contact from 'menu-items/contact';
import menuItem from 'menu-items';
import NavGroup from './NavGroup';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const navGroups = menuItem.items.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'space-between',
        justifyContent: 'space-between',
      }}>
      <Box sx={{ pt: 2 }}>{navGroups}</Box>
      <Box sx={{ position: 'absolute', bottom: 20, left: 0, right: 0 }}>
        <NavGroup item={contact} />
      </Box>
    </Box>
  );
};

export default Navigation;
