// material-ui
import { InputAdornment, OutlinedInput } from '@mui/material';
import { styled } from '@mui/material/styles';

// assets
import { SearchOutlined } from '@ant-design/icons';

// hooks import
import useResponsive from 'hooks/useResponsive';

const StyledSearch = styled(OutlinedInput)(({ theme }) => {
  const isXs = useResponsive('down', 'md');

  return {
    width: isXs ? 130 : 240,
    height: 36,
    transition: theme.transitions.create(['box-shadow', 'width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {
      width: isXs ? 130 : 320,
    },
    '& fieldset': {
      borderWidth: '1px !important',
    },
  };
});

const Search = ({ onChange = () => {}, searchFilterInput = '' }) => (
  <StyledSearch
    defaultValue={searchFilterInput}
    onChange={onChange}
    placeholder="Search ..."
    startAdornment={
      <InputAdornment position="start" sx={{ fontSize: 18 }}>
        <SearchOutlined sx={{ p: 0 }} />
      </InputAdornment>
    }
  />
);

export default Search;
