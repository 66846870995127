// constants import
import reactRouterConstants from 'assets/constants/reactRouterConstants';

// assets
import { TbDeviceDesktopAnalytics } from 'react-icons/tb';
import { RiNodeTree } from 'react-icons/ri';

// icons
const icons = {
  TbDeviceDesktopAnalytics,
  RiNodeTree,
};

// ==============================|| MENU ITEMS - NODE ||============================== //

const nodes = {
  id: 'group-nodes',
  title: 'Node',
  type: 'group',
  children: [
    {
      id: reactRouterConstants.NODES,
      title: 'Nodes',
      type: 'item',
      url: reactRouterConstants.NODES,
      icon: icons.TbDeviceDesktopAnalytics,
      breadcrumbs: false,
    },
  ],
};

export default nodes;
