// material-ui

// ==============================|| OVERRIDES - Text Field ||============================== //

export default function TextField() {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {},
      },
    },
  };
}
