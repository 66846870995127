/*eslint-disable */

// material-ui
import { Box, Tooltip, Typography } from '@mui/material';

// actions import

// assets
import { useSelector } from 'react-redux';

// project import
import AnimateButton from 'components/@extended/AnimateButton';

import { FaSyncAlt } from 'react-icons/fa';
// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const { isUpdating } = useSelector((state) => state.node);

  return (
    <>
      <Tooltip title="Node Update Status">
        <Box sx={{ flexShrink: 0, ml: 0.75, display: 'flex', fontWeight: 600, fontSize: 13 }}>
          <AnimateButton type={isUpdating ? 'spining' : 'scale'}>
            <FaSyncAlt style={{ width: 16, height: 16, color: isUpdating ? '#5ce2b2' : '#ccc' }} />
          </AnimateButton>
          <Typography sx={{ pl: 1, color: '#757981' }} variant="subtitle4">
            Update Status
          </Typography>
        </Box>
      </Tooltip>
    </>
  );
};

export default Profile;
