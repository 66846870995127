import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// constants import
import reactRouterConstants from 'assets/constants/reactRouterConstants';

// hooks import
import useResponsive from 'hooks/useResponsive';

// third party
import { useDispatch } from 'react-redux';
import { activeItem, openDrawer } from 'store/reducers/menu';

// assets import

// material-ui
import { ButtonBase } from '@mui/material';

// project import
import Logo from './Logo';

// ========|| MAIN LOGO ||======== //

const LogoSection = ({ sx }) => {
  const dispatch = useDispatch();
  const isSmallScreen = useResponsive('down', 'sm');

  const itemHandler = () => {
    dispatch(activeItem({ openItem: [reactRouterConstants.HOME] }));
    if (isSmallScreen) {
      dispatch(openDrawer({ drawerOpen: false }));
    }
  };

  return (
    <ButtonBase
      disableRipple
      component={Link}
      to={reactRouterConstants.HOME}
      sx={sx}
      onClick={itemHandler}
    >
      <Logo />
    </ButtonBase>
  );
};

LogoSection.propTypes = {
  sx: PropTypes.object,
  to: PropTypes.string,
};

export default LogoSection;
