/* eslint-disable */

import React, { useState, useEffect } from 'react';

// mui
import { Typography, Link, Box, Divider, Button, Modal, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useSelector } from 'react-redux';

import ReactApexChart from 'react-apexcharts';

// project imports
import MainCard from 'components/MainCard';

import { toast } from 'react-toastify';

// utils
import downloadFile from 'utils/downloadFile';
import isJsonString from 'utils/isJsonString';

// icons
import { TbDatabaseExport, TbFileImport } from 'react-icons/tb';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import sleep from 'utils/sleep';

const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
};

function LocalStorage() {
  const { nodes, isUploading } = useSelector((state) => state.node);
  const [isImporting, setIsImporting] = useState(false);

  const [data, setData] = useState('');
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setOpen(false);
  };

  const [series, setSeries] = useState([0, 0, 0, 0]);
  useEffect(() => {
    let lsLimit = localStorage.getItem('size') || 5000;
    let lsTotal = 0;
    let lsOther = 0;
    let lsNodes = 0;
    let lsWallets = 0;
    let xLen;
    let x;
    for (x in localStorage) {
      if (!localStorage.hasOwnProperty(x)) {
        continue;
      }
      xLen = (localStorage[x].length + x.length) * 2;
      lsTotal += xLen;
      if (!['nodes', 'wallets'].includes(x)) lsOther += xLen;
      if (x === 'nodes') lsNodes = xLen;
      if (x === 'wallets') lsWallets = xLen;
      // console.log(x.substr(0, 50) + ' = ' + (xLen / 1024).toFixed(2) + ' KB');
    }

    const lsEmpty = 5000 - lsNodes / 1024 - lsWallets / 1024 - lsOther / 1024;

    setSeries([lsNodes / 1024, lsWallets / 1024, lsOther / 1024, lsEmpty]);
  }, [isUploading, nodes]);

  const options = {
    chart: {
      height: 390,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: '30%',
          background: 'transparent',
          image: undefined,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
      },
    },
    colors: ['#5769df', '#faad14', '#ff4d4f', '#ccc'],
    fill: {
      // type: 'gradient',
    },
    legend: {
      show: true,
      //   floating: true,
      //   fontSize: '16px',
      position: 'bottom',
      //   offsetX: 160,
      //   offsetY: 15,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        size: 0,
      },
      formatter: (seriesName, opts) =>
        /* eslint-disable-next-line */
        seriesName + ':  ' + opts.w.globals.series[opts.seriesIndex].toFixed(2) + ' KB',
      itemMargin: {
        vertical: 3,
      },
    },
    labels: ['Nodes', 'Wallets', 'Other', 'Available'],
  };

  const handleExportData = () => {
    try {
      const data = JSON.stringify(localStorage);
      downloadFile({ data, fileName: 'NknAppLocalStorage.json', fileType: 'application/json' });
      toast.success('Data exported successfully');
    } catch (e) {
      toast.error(e.message);
    }
  };

  const handleImportData = (e) => {
    try {
      const file = e.target.files[0];
      const reader = new FileReader();

      if (file) {
        reader.readAsText(file);
        reader.onload = (e) => {
          if (!isJsonString(e.target.result)) {
            toast.error('Invalid data');
          } else {
            const data = JSON.parse(e.target.result);
            setData(data);
            setOpen(true);
          }
        };
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      e.target.value = null;
    }
  };

  const handleImportDataConfirm = async () => {
    setIsImporting(true);
    while (isUploading) {
      await sleep(300);
    }
    const dataKeys = Object.keys(data);

    // console.log(dataKeys);

    // if (!dataKeys.every((key) => isJsonString(data[key]))) {
    //   toast.error('Invalid data');
    //   setIsImporting(false);
    //   return;
    // }

    dataKeys.forEach((key) => {
      if (isJsonString(data[key])) {
        localStorage.setItem(key, data[key]);
      }
    });
    // setOpen(false);
    // setData('');
    setIsImporting(false);
    await sleep(300);
    window.location.reload();
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
        <Button
          component="label"
          size="small"
          variant="outlined"
          color="warning"
          endIcon={<TbFileImport />}>
          Import
          <input onChange={handleImportData} type="file" accept="application/JSON" hidden />
        </Button>
        &nbsp;
        <Button
          color="info"
          onClick={handleExportData}
          size="small"
          variant="outlined"
          endIcon={<TbDatabaseExport />}>
          Export
        </Button>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h5" color="text.secondary">
          Local Storage&nbsp;
          <Tooltip title="Check browser local storage limit">
            <Link href="https://arty.name/localstorage.html" target="_blank">
              <AiOutlineQuestionCircle />
            </Link>
          </Tooltip>
        </Typography>
      </Box>
      <ReactApexChart options={options} series={series} type="donut" height={350} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <MainCard title="Are you sure ?">
            <Typography id="modal-modal-description">
              This will overwrite your current local storage data.
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                sx={{
                  width: 90,
                }}
                disabled={isImporting}
                size="small"
                onClick={handleClose}
                variant="outlined">
                Cancel
              </Button>
              <Box sx={{ width: 20 }} />
              <LoadingButton
                sx={{
                  width: 90,
                }}
                loading={isImporting}
                onClick={handleImportDataConfirm}
                size="small"
                variant="outlined"
                color="error">
                Import
              </LoadingButton>
            </Box>
          </MainCard>
        </Box>
      </Modal>
    </Box>
  );
}

export default LocalStorage;
