import { createListenerMiddleware } from '@reduxjs/toolkit';
// constants import
import localStorageConstants from 'assets/constants/localStorageConstants';

// actions import
import { setTimeUnit, setTimeValue, setUpdatedAtLastHours, setNodes } from './reducers/node';
import { setWallets, setTrackingWallets } from './reducers/wallet';
import { openDrawer, openComponentDrawer } from './reducers/menu';
import { setMinutesToUpdate, setChunkSize, setRequestTimeout } from './reducers/setting';

// ==============================|| REDUX TOOLKIT - MIDDLEWARE ||============================== //
const listenerMiddleware = createListenerMiddleware();

// node middleware
listenerMiddleware.startListening({
  actionCreator: setTimeUnit,
  effect: (action) => {
    localStorage.setItem(localStorageConstants.NODE_TIME_UNIT, action.payload);
  },
});

listenerMiddleware.startListening({
  actionCreator: setTimeValue,
  effect: (action) => {
    localStorage.setItem(localStorageConstants.NODE_TIME_VALUE, action.payload);
  },
});

listenerMiddleware.startListening({
  actionCreator: setNodes,
  effect: (action) => {
    localStorage.setItem(localStorageConstants.NODES, JSON.stringify(action.payload));
  },
});

// setting middleware
listenerMiddleware.startListening({
  actionCreator: setMinutesToUpdate,
  effect: (action) => {
    localStorage.setItem(localStorageConstants.MINUTES_TO_UPDATE, action.payload);
  },
});
listenerMiddleware.startListening({
  actionCreator: setChunkSize,
  effect: (action) => {
    localStorage.setItem(localStorageConstants.CHUNK_SIZE, action.payload);
  },
});

listenerMiddleware.startListening({
  actionCreator: setRequestTimeout,
  effect: (action) => {
    localStorage.setItem(localStorageConstants.REQUEST_TIMEOUT, action.payload);
  },
});

// wallet middleware
listenerMiddleware.startListening({
  actionCreator: setWallets,
  effect: (action) => {
    localStorage.setItem(localStorageConstants.WALLETS, JSON.stringify(action.payload));
  },
});

listenerMiddleware.startListening({
  actionCreator: setTrackingWallets,
  effect: (action) => {
    localStorage.setItem(localStorageConstants.TRACKING_WALLETS, JSON.stringify(action.payload));
  },
});

// menu side bar middleware
listenerMiddleware.startListening({
  actionCreator: openDrawer,
  effect: (action) => {
    localStorage.setItem(
      localStorageConstants.DRAWER_OPEN,
      JSON.stringify(action.payload.drawerOpen),
    );
  },
});
listenerMiddleware.startListening({
  actionCreator: openComponentDrawer,
  effect: (action) => {
    localStorage.setItem(
      localStorageConstants.COMPONENT_DRAWER_OPEN,
      JSON.stringify(action.payload.componentDrawerOpen),
    );
  },
});

listenerMiddleware.startListening({
  actionCreator: setUpdatedAtLastHours,
  effect: (action) => {
    localStorage.setItem(localStorageConstants.NODE_UPDATED_HOURS, action.payload);
  },
});

export default listenerMiddleware;
