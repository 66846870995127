// assets
import { AiOutlineGithub } from 'react-icons/ai';
import { FaTelegramPlane, FaDiscord } from 'react-icons/fa';
// icons
const icons = {
  AiOutlineGithub,
  FaTelegramPlane,
  FaDiscord,
};

// ==============================|| MENU SUPPORT - SUPPORT ||============================== //

const contact = {
  id: 'group-support',
  title: 'Contact',
  type: 'group',
  children: [
    {
      id: 'github',
      title: 'Github',
      type: 'item',
      target: '_blank',
      url: 'https://github.com/nknagent',
      icon: icons.AiOutlineGithub,
      breadcrumbs: false,
    },
    {
      id: 'discord',
      title: 'Discord',
      type: 'item',
      url: 'https://discord.gg/AkXMr7JD',
      target: '_blank',
      icon: icons.FaDiscord,
    },
    {
      id: 'official',
      title: 'Telegram',
      type: 'item',
      target: '_blank',
      url: 'https://t.me/nknorg',
      icon: icons.FaTelegramPlane,
      breadcrumbs: false,
    },
  ],
};

export default contact;
