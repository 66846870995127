import { memo } from 'react';
import { Grid, Box, Typography } from '@mui/material';

// utils
import { fNumber } from 'utils/formatNumber';

// project import
import MainCard from 'components/MainCard';

// icons import
import { RiseOutlined, FallOutlined } from '@ant-design/icons';

function MarketStatistics({ nknMarketData }) {
  return (
    <MainCard>
      <Typography sx={{ px: 4, pb: 4, pt: 2 }} variant="h5">
        Market Statistics
      </Typography>
      <Grid container spacing={3} sx={{ px: 4, pb: 3 }}>
        <Grid item xs={6} md={6} lg={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography color="textSecondary" sx={{ fontSize: 17 }}>
              Price
            </Typography>
            <Typography sx={{ fontSize: 19, color: 'success.main' }}>
              ${nknMarketData?.current_price?.usd}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography color="textSecondary" sx={{ fontSize: 17 }}>
              Change 24h
            </Typography>
            <Typography
              sx={{ fontSize: 19 }}
              color={nknMarketData?.price_change_percentage_24h > 0 ? 'success.main' : 'error.main'}
            >
              {nknMarketData?.price_change_percentage_24h > 0 ? <RiseOutlined /> : <FallOutlined />}{' '}
              {nknMarketData?.price_change_percentage_24h}%
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography color="textSecondary" sx={{ fontSize: 17 }}>
              Market Cap
            </Typography>
            <Typography sx={{ fontSize: 19 }}>
              ${fNumber(nknMarketData?.market_cap?.usd)}
            </Typography>
          </Box>
        </Grid>{' '}
        <Grid item xs={6} md={6} lg={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography color="textSecondary" sx={{ fontSize: 17 }}>
              Daily Volumn
            </Typography>
            <Typography sx={{ fontSize: 19 }}>
              ${fNumber(nknMarketData?.total_volume?.usd)}
            </Typography>
          </Box>
        </Grid>{' '}
        <Grid item xs={6} md={6} lg={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography color="textSecondary" sx={{ fontSize: 17 }}>
              NKN/USD
            </Typography>
            <Typography sx={{ fontSize: 19 }}>${nknMarketData?.current_price?.usd}</Typography>
          </Box>
        </Grid>{' '}
        <Grid item xs={6} md={6} lg={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography color="textSecondary" sx={{ fontSize: 17 }}>
              NKN/ETH
            </Typography>
            <Typography sx={{ fontSize: 19 }}>{nknMarketData?.current_price?.eth}</Typography>
          </Box>
        </Grid>
      </Grid>
    </MainCard>
  );
}

export default memo(MarketStatistics);
