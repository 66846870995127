import { useState } from 'react';
import { Box, Typography, Stack, Tooltip, Link, Modal, Divider, Button } from '@mui/material';
import { IoWalletOutline } from 'react-icons/io5';
// import { BiCopy } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';

// toast
// import { toast } from 'react-toastify';

// component import
import MainCard from 'components/MainCard';
// import InlineEdit from 'components/InlineEdit';

// utils import
import { fNumber2, fCurrency } from 'utils/formatNumber';
import nanoNknToNkn from 'utils/nanoNknToNkn';

const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: 300,
    lg: 400,
  },
};

function WalletCard({
  address = 'address...',
  balance = 0,
  usdPrice,
  name = 'name',
  onDelete,
  title = 'wallet name',
}) {
  // const handleCopyWalletAddrToClipBoard = () => {
  //   const copyData = address;
  //   navigator.clipboard.writeText(copyData);
  //   toast.info('Copied wallet address');
  // };

  const [open, setOpen] = useState(false);

  const handleCloseConfirmModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setOpen(false);
  };

  const handleDeleteWallet = () => {
    onDelete(address);
    setOpen(false);
  };

  return (
    <MainCard>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          overflow: 'hidden',
        }}>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              overflow: 'hidden',
              justifyContent: 'space-between',
              width: '100%',
            }}>
            <Typography
              sx={{
                width: '90%',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                opacity: '.8',
                height: 29.5,
              }}
              title={address}>
              <Link
                href={`https://nscan.io/addresses/${address}`}
                target="_blank"
                rel="noreferrer"
                color="primary">
                {address}
              </Link>
            </Typography>
            <Tooltip title="Delete wallet">
              <Box
                onClick={() => {
                  setOpen(true);
                }}
                sx={{
                  fontSize: 19,
                  cursor: 'pointer',
                  color: (theme) => theme.palette.error.main,
                  transition: '.3s',
                  '&:hover': {
                    color: '#ccc',
                  },
                }}>
                {/* <BiCopy /> */}
                {onDelete && <AiOutlineDelete />}
              </Box>
            </Tooltip>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', position: 'relative' }}>
          <Box
            sx={{
              width: 69,
              height: 69,
              bgcolor: '#5e7cf1',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: 35,
              color: '#fff',
              borderRadius: '10px',
            }}>
            <IoWalletOutline />
          </Box>
          <Box
            sx={{
              ml: 2,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              width: '70%',
            }}>
            <Box sx={{ display: 'flex', overflow: 'hidden', justifyContent: 'space-between' }}>
              <Box direction="row">
                <span style={{ fontWeight: 600, fontSize: 25, color: 'var(--text-secondary)' }}>
                  {fNumber2(nanoNknToNkn(balance))}
                </span>
                <span style={{ fontSize: 16, marginLeft: 3, fontWeight: 500 }}>NKN</span>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', overflow: 'hidden', justifyContent: 'space-between' }}>
              <Stack direction="row">
                <Typography variant="h5" color={'success.dark'}>
                  {fCurrency(usdPrice * nanoNknToNkn(balance))}
                </Typography>
              </Stack>
            </Box>
          </Box>
          <Box
            sx={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              p: 0.5,
              fontSize: 18,
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            }}>
            <Typography sx={{ fontSize: 16 }} color="info.main">
              <Tooltip title={title}>
                <i>{name}</i>
              </Tooltip>
            </Typography>
            {/* <AiOutlineDelete /> */}
          </Box>
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleCloseConfirmModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <MainCard title="Are you sure ?">
            <Typography id="modal-modal-description">
              You are about to delete this wallet. This action cannot be undone.
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                sx={{
                  width: 90,
                }}
                size="small"
                onClick={handleCloseConfirmModal}
                variant="outlined">
                No
              </Button>
              <Box sx={{ width: 20 }} />
              <Button
                sx={{
                  width: 90,
                }}
                onClick={handleDeleteWallet}
                size="small"
                variant="outlined"
                color="error">
                Yes
              </Button>
            </Box>
          </MainCard>
        </Box>
      </Modal>
    </MainCard>
  );
}

export default WalletCard;
