// constants import
import reactRouterConstants from 'assets/constants/reactRouterConstants';
// assets
import { IoSettingsOutline } from 'react-icons/io5';
import { VscDesktopDownload } from 'react-icons/vsc';

// icons
const icons = {
  IoSettingsOutline,
  VscDesktopDownload,
};

// ==============================|| MENU SETTING - SETTING ||============================== //

const setting = {
  id: 'group-setting',
  title: 'App Setting',
  type: 'group',
  children: [
    {
      id: reactRouterConstants.SETTING,
      title: 'Setting',
      type: 'item',
      url: reactRouterConstants.SETTING,
      icon: icons.IoSettingsOutline,
      breadcrumbs: false,
    },
    {
      id: 'install',
      title: 'Install',
      type: 'item',
      url: '#',
      icon: icons.VscDesktopDownload,
      breadcrumbs: false,
    },
  ],
};

export default setting;
