import React, { useState, useMemo } from 'react';

// lib import
import moment from 'moment';

// mui
import { Box, Typography, Skeleton } from '@mui/material';

// redux
import { useSelector } from 'react-redux';

// project import
import MainCard from 'components/MainCard';

// third party
import ReactApexChart from 'react-apexcharts';

const SYNC_STATE_PALETTE = [
  {
    state: 'PERSIST_FINISHED',
    color: '#00AF91',
  },
  {
    state: 'OFFLINE',
    color: '#4E8397',
  },
  {
    state: 'INTERNAL_ERROR',
    color: '#ff5149',
  },
  {
    state: 'INSTALL',
    color: '#845EC2',
  },
  {
    state: 'INSTALLED',
    color: '#D65DB1',
  },
  {
    state: 'SYNC_STARTED',
    color: '#FF6F91',
  },
  {
    state: 'WAIT_FOR_SYNCING',
    color: '#FF9671',
  },
  {
    state: 'SYNC_FINISHED',
    color: '#2C73D2',
  },
  {
    state: 'NO_ID',
    color: '#B7C4CF',
  },
  {
    state: 'UNKNOWN',
    color: '#EEE3CB',
  },
  {
    state: 'INSTALL_ERROR',
    color: '#967E76',
  },
  {
    state: 'OTHER',
    color: '#434242',
  },
];

const preState = SYNC_STATE_PALETTE.map((item) => item.state);

function NodesStatusChart() {
  const {
    fromMonth,
    toMonth,
    updatedAtLastHours,
    nodes: persistNode,
  } = useSelector((state) => state.node);

  // theme

  const [series, setSeries] = useState(null);
  const [chartLabels, setChartLabel] = useState([]);
  const [stateColors, setStateColors] = useState([]);

  const timeFilter = useMemo(() => {
    const now = moment();
    if (updatedAtLastHours === 'none') return {};
    return {
      updatedAt: {
        gte: now.subtract(updatedAtLastHours, 'hour').toISOString(),
      },
    };
  }, [updatedAtLastHours]);

  // distinct api
  React.useEffect(() => {
    (async () => {
      try {
        const stateArr = persistNode.map((node) => node.syncState);
        const stateCount = {};

        stateArr.forEach((state) => {
          stateCount[state] = (stateCount[state] || 0) + 1;
        });

        const resSyncState = Array.from(new Set(stateArr));

        const result = resSyncState.map((state) => ({
          state,
          count: stateCount[state] || 0,
        }));

        const exceptionSyncStates = result.filter((status) => !preState.includes(status.state));

        const syncStatesResult = result.filter((status) => preState.includes(status.state));
        const otherSyncState = {
          state: 'OTHER',
          count: 0,
        };
        /* eslint-disable-next-line */
        for (const state of exceptionSyncStates) {
          otherSyncState.count += state.count;
        }

        if (otherSyncState.count > 0) {
          syncStatesResult.push(otherSyncState);
        }

        const colors = syncStatesResult.map(
          (status) =>
            /* eslint-disable-next-line */
            SYNC_STATE_PALETTE.find((item) => item.state === status.state)?.color || '#434242',
        );

        setStateColors(colors);
        setSeries(syncStatesResult.map((status) => status?.count));
        setChartLabel(syncStatesResult.map((status) => status?.state));
      } catch (error) {
        console.log(error);
      }
    })();
  }, [fromMonth, toMonth, timeFilter, persistNode]);

  const options = useMemo(
    () => ({
      chart: {
        width: '100%',
        animations: {
          enabled: true,
          easing: 'linear',
          speed: 500,
          animateGradually: {
            enabled: true,
            delay: 250,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 300,
          },
        },
      },
      legend: {
        position: 'bottom',
        show: true,
        showForSingleSeries: true,
        showForNullSeries: true,
        showForZeroSeries: true,
        horizontalAlign: 'left',
        onItemClick: {
          toggleDataSeries: true,
        },
        formatter(val, opts) {
          return `${val} (${opts.w.globals.series[opts.seriesIndex]})`;
        },
        fontWeight: 600,
      },
      labels: chartLabels,
      theme: {
        monochrome: {
          enabled: false,
          // color: '#000',
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            show: false,
          },
        },
      },
      dataLabels: {
        formatter(val, opts) {
          const name = opts.w.globals.labels[opts.seriesIndex];
          return [name, `${val.toFixed(1)} %`];
        },
        show: false,
        background: {
          dropShadow: {
            enable: true,
            blur: 30,
            opacity: 0.3,
            color: '#000',
          },
        },
      },
      colors: stateColors,
    }),
    [stateColors, series, chartLabels],
  );

  /*eslint-disable */
  return (
    <MainCard>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography align="left" variant="h5">
          Nodes Status
        </Typography>
        &nbsp;
        <Typography variant="h6">
          {updatedAtLastHours === 'none' ? '' : `(Last ${updatedAtLastHours}h)`}
        </Typography>
      </Box>
      {series ? (
        <ReactApexChart options={options} series={series} type="pie" height={350} />
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}>
          <Skeleton variant="circular" animation="wave" width={250} height={250} />
          <Skeleton animation="wave" width={'100%'} height={60} sx={{ mt: 1 }} />
        </Box>
      )}
    </MainCard>
  );
}

export default NodesStatusChart;
