// constants import
import reactRouterConstants from 'assets/constants/reactRouterConstants';

// project import
import MainLayout from 'layout/MainLayout';

// render - dashboard
import DashboardDefault from 'pages/dashboard';
import WalletsPage from 'pages/wallets';
import NodesPage from 'pages/nodes';
import Setting from 'pages/setting';

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: reactRouterConstants.HOME,
  element: <MainLayout />,
  children: [
    {
      path: reactRouterConstants.HOME,
      element: <DashboardDefault />,
    },
    {
      path: reactRouterConstants.WALLETS,
      element: <WalletsPage />,
    },
    {
      path: reactRouterConstants.NODES,
      element: <NodesPage />,
    },
    {
      path: reactRouterConstants.SETTING,
      element: <Setting />,
    },
  ],
};

export default MainRoutes;
