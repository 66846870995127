import axios from 'axios';

const nknApi = {
  async getNKNMarketHistory({ day = 1, currency = 'usd' }) {
    const url = `https://api.coingecko.com/api/v3/coins/nkn/market_chart?vs_currency=${currency}&days=${day}`;
    return axios.get(url);
  },
  async getNKNCurrentData() {
    const url = 'https://api.coingecko.com/api/v3/coins/nkn?localization=false';
    return axios.get(url);
  },

  async getNKNPrice() {
    const url = 'https://api.coingecko.com/api/v3/simple/price?ids=nkn&vs_currencies=usd';
    return axios.get(url);
  },
};

export default nknApi;
