import { useState, useRef } from 'react';

import { AiOutlineEdit } from 'react-icons/ai';

const InlineEdit = ({ value, setValue = () => {}, id }) => {
  const [editingValue, setEditingValue] = useState(value);
  const [inputOverlay, setInputOverlay] = useState(true);
  const [showEditBtn, setShowEditBtn] = useState(false);

  const inputRef = useRef(null);

  const onChange = (event) => setEditingValue(event.target.value);

  const onKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      event.target.blur();
    }
  };

  const onBlur = (event) => {
    if (event.target.value.trim() === '') {
      setEditingValue(value);
    } else {
      setValue(id, event.target.value);
    }
    setInputOverlay(true);
  };

  const handleEnableEdit = () => {
    setInputOverlay(false);
    setEditingValue(value);
    inputRef.current.focus();
  };

  return (
    <div
      onMouseEnter={() => {
        setShowEditBtn(true);
      }}
      onMouseLeave={() => setShowEditBtn(false)}
      style={{ display: 'flex', padding: '5px 0px' }}>
      <div
        style={{
          position: 'relative',
          boxShadow: inputOverlay ? 'none' : '0 0 0 2px rgb(24 144 255 / 20%)',
          border: `1px solid ${inputOverlay ? 'transparent' : '#1890ff'}`,
          borderRadius: '4px',
          paddingLeft: '3px',
          minWidth: '50px',
          maxWidth: '70px',
        }}>
        <input
          type="text"
          ref={inputRef}
          aria-label="Field name"
          style={{
            border: 'none',
            outline: 'none',
            width: '100%',
            background: 'transparent',
          }}
          value={editingValue}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
        />
        {inputOverlay && <div style={{ position: 'absolute', inset: '0 0 0 0', zIndex: 1 }}></div>}
        {showEditBtn && (
          <div
            style={{
              position: 'absolute',
              right: 2,
              top: 2,
              bottom: 2,
              zIndex: 2,
              width: '25px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '17px',
              cursor: 'pointer',
              color: '#1890ff',
            }}
            onClick={handleEnableEdit}>
            <AiOutlineEdit />
          </div>
        )}
        {/* {editingValue && (
          <button style={{ position: 'absolute', right: 0, zIndex: 2 }}>save</button>
        )} */}
      </div>
    </div>
  );
};

export default InlineEdit;
