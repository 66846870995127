/*eslint-disable */

import { useSelector } from 'react-redux';

import logo from '../../assets/images/icons/logo.svg';
import logo2 from '../../assets/images/icons/logo2.svg';

const Logo = ({ width }) => {
  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;

  let logoSize = 35;

  if (drawerOpen) {
    logoSize = 50;
  }

  return (
    <div
      style={{
        backgroundImage: `url(${drawerOpen ? logo : logo2})`,
        height: logoSize,
        width: logoSize * 4,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundSize: drawerOpen ? '45%' : null,
      }}
      alt="NKN"
    />
  );
};

export default Logo;
