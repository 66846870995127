// project import
import dashboard from './dashboard';
import wallets from './wallets';
import nodes from './nodes';
import setting from './setting';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, nodes, wallets, setting],
};

export default menuItems;
