// redux
import { useSelector, useDispatch } from 'react-redux';
import { setUpdatedAtLastHours } from 'store/reducers/node';

// @mui
import { FormControl, MenuItem, Select, InputLabel } from '@mui/material';

function NodeUpdatedAtFilter({ disabled = false }) {
  const { updatedAtLastHours } = useSelector((state) => state.node);
  const dispatch = useDispatch();

  const handleChangeTimeFilter = (e) => {
    const { value } = e.target;
    dispatch(setUpdatedAtLastHours(value));
  };

  return (
    <FormControl disabled={disabled} sx={{ minWidth: 100 }}>
      <InputLabel sx={{}} id="updated-at-select-label">
        Updated At
      </InputLabel>
      <Select
        // labelId="updated-at-select-label"
        id="updated-at-select"
        value={updatedAtLastHours}
        label="Updated At"
        size="small"
        onChange={handleChangeTimeFilter}>
        <MenuItem value="none">Default</MenuItem>
        {[
          { title: 'Last 1h', value: 1 },
          { title: 'Last 2h', value: 2 },
          { title: 'Last 3h', value: 3 },
          { title: 'Last 6h', value: 6 },
          { title: 'Last 12h', value: 12 },
        ].map((state) => (
          <MenuItem key={state.value} value={state.value}>
            {state.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default NodeUpdatedAtFilter;
