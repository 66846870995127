import { useRoutes } from 'react-router-dom';

// project import
import MainRoutes from './MainRoutes';
import NotFoundRoutes from './NotFoundRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([MainRoutes, NotFoundRoutes]);
}
